<template>
    <div class="main_Div">
        <div class="headBox" ref="headBox">
            <div class="headBoxLeftBox">
                <ul>
                    <li @click="chooseFirst(item)" v-for="item in firstMenuList" :key="item.parameter" :class="[params.firstMenu === item.parameter ? 'itemBoxActive' : 'itemBox']"><div>{{item.name}}</div><i class="el-icon-caret-right"></i></li>
                </ul>
            </div>
            <div class="headBoxRightBox">
                <div class="headBoxRightMenuBox" v-if="params.firstMenu">
                    <div class="topBox">
                        <p>{{params.firstMenuName}}</p>
                        <p>
                            <span @click="chooseSecond(item)" v-for="item in secondMenuList" :key="item.parameter" :class="{'secondActive':item.parameter === params.secondMenu}">{{item.name}}</span>
                        </p>
                    </div>
                    <div class="bottomBox">
                        <router-link :to="{path:'/course/detail',query:{detailId:item.id}}" target="_blank" class="itemContentBox" v-for="item in fourListData" :key="item.id">
                            <div class="imgBox"><img :src="item.img" alt=""></div>
                            <div class="descBox">
                                <div><div>{{item.attr}}</div></div>
                                <div>{{item.title}}</div>
                                <div>{{item.sections}}节 | {{item.time_long}}</div>
                            </div>
                        </router-link>
                    </div>
                </div>
                <el-carousel height="5.01rem" arrow="never">
                    <el-carousel-item v-for="item in bannerList" :key="item.id">
                        <a :href="item.url">
                            <img style="height:100%;width:100%;border-radius: 0.16rem;" :src="item.img" alt="">
                        </a>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>
        <div class="outerBox">
            <div class="middleBox">
                <TypeCourseList :loading="loading" :courseList="courseList"></TypeCourseList>
            </div>
        </div>
        <div style="width: 100%;text-align: center;margin-bottom:0.24rem">
            <el-pagination
                    background
                    layout="prev, pager, next"
                    @current-change="handleCurrentChange"
                    :current-page.sync="searchParams.page"
                    :total="total"
                    :page-size="5"
                    v-if="total"
            ></el-pagination>
        </div>
        <div class="bottomBox">
            <img src="../../assets/image/bg/typeCourseFooter.png" alt="">
            <div class="btnToSign" @click="toSign"></div>
        </div>
    </div>
</template>

<script>
    import {getMenu,classBanner, getCateHotClass,teacherOverview} from "../../Api";
    import TypeCourseList from '@/components/TypeCourseList'
    import {mapGetters} from "vuex";
    // import {getToken} from "../../utils/auth";
    export default {
        name: "TypeCourseIndex",
        components: {
            TypeCourseList
            // Uploader
        },
        computed: {
            ...mapGetters(["isLogin"]),
        },
        created() {
            document.addEventListener('click', (e) => {
                if (this.$refs.headBox) {
                    let isSelf = this.$refs.headBox.contains(e.target)
                    if (!isSelf) {
                        this.params.firstMenu = ''
                    }
                }
            })
        },
        data(){
            return{
                loading: true,
                searchParams: {
                    page: 1,
                },
                firstMenuList:[],
                bannerList: [],
                secondMenuList: [],
                params:{
                    firstMenuName: '',
                    firstMenu: '',
                    secondMenu: '',
                },
                total: 0,
                fourListData: [],
                courseList: []
            }
        },
        mounted() {
            getMenu(6).then(res=>{
                if(res.status == 200){
                    this.firstMenuList = res.data.data
                }
            })
            classBanner().then(res=>{
                if(res.status == 200){
                    this.bannerList = res.data
                }
            })
            this.initData()
        },
        methods:{
            initData(){
                this.loading = true;
                //回到顶部
                cancelAnimationFrame(this.timer);
                const self = this;
                self.timer = requestAnimationFrame(function fn() {
                    const oTop =
                        document.body.scrollTop || document.documentElement.scrollTop;
                    if (oTop > 0) {
                        document.body.scrollTop = document.documentElement.scrollTop =
                            oTop - 100;
                        self.timer = requestAnimationFrame(fn);
                    } else {
                        cancelAnimationFrame(self.timer);
                    }
                });
                this.courseList = []
                teacherOverview(this.searchParams).then(res=>{
                    if(res.status == 200){
                        this.courseList = res.data.data
                        this.total = res.data.total
                        this.loading = false
                    }
                })
            },
            handleCurrentChange(val) {
                this.searchParams.page = val
                this.initData()
            },
            toSign(){
                if(!this.isLogin){
                    this.$login()
                    return
                }
                this.$router.push({ path: "/sign/index" });
            },
            chooseSecond(item){
                this.params.secondMenu = item.parameter
                const {href} = this.$router.resolve({ path: "/course/index",query:{child1:this.params.firstMenu,child2:this.params.secondMenu}});
                window.open(href, '_blank')
                // getCateHotClass({child:this.params.secondMenu}).then(res=>{
                //     if(res.status == 200){
                //         this.fourListData = res.data
                //     }
                // })
            },
            chooseFirst(item){
                this.params.firstMenu = item.parameter
                this.params.firstMenuName = item.name
                getMenu(this.params.firstMenu).then(res=>{
                    if(res.status == 200){
                        this.secondMenuList = res.data.data
                        // this.params.secondMenu = this.secondMenuList[0].parameter
                        getCateHotClass({child:this.params.firstMenu}).then(res=>{
                            if(res.status == 200){
                                this.fourListData = res.data
                            }
                        })
                    }
                })
            }
        }
    }
</script>

<style scoped>
    ::v-deep .is-animated {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .main_Div{
        /*padding: 0 1.2rem;*/
        width: 100%;
        background: linear-gradient(180deg, rgba(255, 152, 65, 0.05) 0%, rgba(255, 141, 26, 0) 100%);;
        padding-top:0.6rem;
    }
    .headBox{
    /*width:*/
    /*    padding: 0.6rem 1.2rem;*/
        margin:auto;
        width:17.2rem;
        height:5.04rem;
        display: flex;
        margin-bottom:0.60rem;
    }
    .headBox>.headBoxLeftBox{
        height:5.04rem;
        width: 2.85rem;
        padding-top:0.12rem;
        margin-right:0.32rem;
        /*padding:0.24rem 0.24rem 0.24rem 0.32rem;*/
        border-radius: 0.16rem;
        background: rgba(255, 255, 255, 1);
        overflow-y: auto;
    }
    .headBox>.headBoxRightBox{
        height:5.04rem;
        width: 14.03rem;
        border-radius: 0.16rem;
        position: relative;
    }
    .headBox>.headBoxLeftBox .itemBox{
        width:2.85rem;
        height:0.48rem;
        line-height: 0.48rem;
        display: flex;
        align-items: center;
        font-size: 0.16rem;
        font-weight: 500;
        letter-spacing: 0px;
        color: rgba(26, 43, 61, 1);
        text-align: left;
        padding:0.24rem 0;
        cursor: pointer;
    }
    .headBox>.headBoxLeftBox .itemBoxActive{
        width:2.85rem;
        height:0.48rem;
        line-height: 0.48rem;
        display: flex;
        align-items: center;
        font-size: 0.16rem;
        font-weight: 500;
        letter-spacing: 0px;
        color: white;
        text-align: left;
        background: rgba(255, 152, 65, 1);
        /*box-shadow: 0px 1px 4px 0px rgba(186, 159, 136, 0.6);*/
        padding:0.24rem 0;
        cursor: pointer;
    }
    .headBox>.headBoxLeftBox .itemBox>div{
        width:1.68rem;
        padding-left:0.24rem;
        margin-right:0.76rem
    }
    .headBox>.headBoxLeftBox .itemBoxActive>div{
        width:1.68rem;
        padding-left:0.24rem;
        margin-right:0.76rem
    }
    .headBoxLeftBox::-webkit-scrollbar { width: 0 !important }
    .headBoxLeftBox { -ms-overflow-style: none; }
    .headBoxLeftBox { overflow: -moz-scrollbars-none; }
    .headBoxRightMenuBox{
        width:12rem;
        height:5.04rem;
        border-radius: 0.32rem;
        background: rgba(255, 255, 255, 1);
        box-shadow: 2px 2px 6px 0px rgba(166, 166, 166, 0.2), -2px -2px 6px 0px rgba(166, 166, 166, 0.2);
        position: absolute;
        left:-0.3rem;
        z-index:4
    }
    .headBoxRightMenuBox>.topBox{
         height:2.02rem;
         width:100%;
         background: rgba(255, 255, 255, 1);
        border-top-left-radius: 0.32rem;
        border-top-right-radius: 0.32rem;
        padding:0.4rem 0.48rem 0.4rem 0.48rem
     }
    .headBoxRightMenuBox>.topBox>p:first-child{
        font-size: 0.16rem;
        font-weight: 500;
    }
    .headBoxRightMenuBox>.topBox>p:nth-child(2){
        margin-top:0.38rem
    }
    .headBoxRightMenuBox>.topBox>p:nth-child(2)>span{
        display: inline-block;
        font-size: 0.14rem;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 0px;
        color: rgba(166, 166, 166, 1);
        text-align: left;
        vertical-align: top;
        margin-right:0.24rem;
        margin-bottom:0.38rem;
        cursor:pointer
    }
    .headBoxRightMenuBox>.topBox>p:nth-child(2)>.secondActive{
        color: rgba(54, 56, 126, 1);
    }
    .headBoxRightMenuBox>.bottomBox{
        height:3.02rem;
        width:100%;
        background: rgba(243, 245, 246, 1);
        border-bottom-left-radius: 0.32rem;
        border-bottom-right-radius: 0.32rem;
        display: flex;
        flex-wrap: wrap;
        /*align-items: center;*/
    }
    .headBoxRightMenuBox>.bottomBox>.itemContentBox{
        width:5.2rem;
        height:0.9rem;
        margin-left:0.48rem;
        margin-top:0.4rem;
        display: flex;
    }
    .imgBox{
        width:1.5rem;
        height:0.9rem;
        border-radius: 0.16rem
    }
    .imgBox>img{
        width:100%;height:100%;
        border-radius: 0.08rem
    }
    .descBox{
        height:0.9rem;
        width:3.2rem;
        margin-left:0.16rem
    }
    .descBox>div{
        height:0.3rem
    }
    .descBox>div:first-child>div{
        width: 0.4rem;
        height: 0.2rem;
        opacity: 1;
        background: rgba(255, 153, 85, 1);
        color:white;
        text-align: center;
        line-height: 0.2rem;
        border-radius:0.06rem
    }
    .descBox>div:nth-child(2){
        font-size: 0.16rem;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 0.3rem;
        color: rgba(56, 56, 56, 1);
        text-align: left;
        vertical-align: top;
        white-space:nowrap;
        text-overflow:ellipsis;
        -o-text-overflow:ellipsis;
        overflow: hidden;
     }
    .descBox>div:nth-child(3){
        font-size: 0.12rem;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 0.3rem;
        color: rgba(128, 128, 128, 1);
        text-align: left;
        vertical-align: top;
    }
    .outerBox{
        background: rgba(247, 246, 251, 1);
        width:100%;
        padding-top:0.6rem;
        /*padding-bottom:0.5rem*/
    }
    .middleBox{
        width:17.2rem;margin:auto;

    }
    .bottomBox{
        width:100%;
        background: white;
        height:5rem;
        position: relative;
    }
    .bottomBox>img{
        height:4.5rem;
        width:100%;
        margin-top:0.5rem;
    }
    .btnToSign{
        width: 2.4rem;
        height:0.6rem;
        position: absolute;
        left: 8.2rem;
        top: 3.15rem;
        cursor: pointer;
    }
</style>
